import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Airtable from "airtable"
import FlipImage from "../social-supermarket/components/image/FlipImage"

const Container = styled.div`
  width: 750px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
`

const Label = styled.div`
  width: 33.33%;
  border: 1px solid black;
  padding: 5px;
  font-size: 0.7em;

  p {
    margin-bottom: 5px;
  }
`

const ImageContainer = styled.div`
  width: 75px;
  float: left;
  margin-right: 10px;
`

const Labels = () => {
  const [allRecords, setRecords] = useState([])
  useEffect(() => {
    const base = new Airtable({ apiKey: "keyfCZJYDm3xqkw19" }).base("appejhC2uBmY7bdrZ")

    base("Stock")
      .select({
        maxRecords: 200,
        view: "All Data",
        filterByFormula: "{Print Label}",
      })
      .eachPage(
        (records, fetchNextPage) => {
          setRecords(records.map(record => record.fields))
          fetchNextPage()
        },
        err => {
          if (err) {
            console.error(err)
          }
        }
      )
  }, [])

  return (
    <Container>
      {allRecords
        .filter(record => record["Print Label"])
        .map(record => (
          <LabelComponent record={record} />
        ))}
    </Container>
  )
}

const LabelComponent = ({ record }) => {
  const src = record["Photo"] && record["Photo"].length > 0 ? record["Photo"][0].url : ""
  return (
    <Label>
      <ImageContainer>
        <FlipImage image={{ src }} />
      </ImageContainer>
      <p>
        <b>{record["Name"]}</b>
      </p>
      <p>{record["Brand Name"]}</p>
    </Label>
  )
}

export default Labels
